
form[name=shop-switch-form] {
  display: flex;
}
form[name=shop-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=shop-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=shop-switch-form] > div > div {
  flex-grow:1;
}
form[name=shop-switch-form] > button {
  margin-left:10px;
}


.sweetness input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  position: absolute;
  bottom:0px;
}
.sweetness input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.sweetness input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
.sweetness input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
.sweetness input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
  margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.sweetness input[type=range]::-moz-range-thumb {
  border: 0px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
}

/* All the same stuff for IE */
.sweetness input[type=range]::-ms-thumb {
  border: 0px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
}
.sweetness input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
}
.sweetness input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
.sweetness input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
}
.sweetness input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.sweetness input[type=range]::-ms-fill-lower {
  background: transparent;
}
.sweetness input[type=range]:focus::-ms-fill-lower {
  background: transparent;
}
.sweetness input[type=range]::-ms-fill-upper {
  background: transparent;
}
.sweetness input[type=range]:focus::-ms-fill-upper {
  background: transparent;
}
.sweetness .scale {
    position: relative;
    width:436px;
    height: 15px;
    background:transparent;
    border-bottom:4px solid #555;
    margin:0px 7px;
}
.sweetness .scale > i {
    width:2px;
    height: 15px;
    background:#555;
    position:absolute;
}
.sweetness .legend {
    display:flex;
}
.sweetness .legend > span {
    flex-grow:1;
    text-align:center;
    text-transform: uppercase;
}
.sweetness .legend > span:first-child {
    text-align:left
}
.sweetness .legend > span:last-child {
    text-align:right
}
.sweetness {
    position:relative;
    width: 450px;
}
.sweetness .scale > i:nth-child(1) {
    left:0px;
}
.sweetness .scale > i:nth-child(2) {
    left:143px;
}
.sweetness .scale > i:nth-child(3) {
    left:287px;
}
.sweetness .scale > i:nth-child(4) {
    right:0px;
}


.localizedText .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedText textarea {
  border-top:0px;
}
.localizedText.has-success .nav-tabs {
  border-color: #1ab394;
}
.localizedText.has-error .nav-tabs {
  border-color: #ed5565;
}
.localizedText.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedText.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}


.product-form .update-description textarea {
  min-height: 100px;
}


:root {
  --ck-z-default: 2200
}
.localizedContent .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedContent.has-success .nav-tabs, 
.localizedContent.has-success .ck.ck-toolbar {
  border-color: #1ab394;
}
.localizedContent.has-error .nav-tabs,
.localizedContent.has-error .ck.ck-toolbar {
  border-color: #ed5565;
}
.localizedContent.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedContent.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}
.localizedContent .ck.ck-toolbar {
  border-top:0px;
}
.localizedContent.has-success .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.localizedContent.has-success .ck.ck-editor__main>.ck-source-editing-area>textarea {
  border-color: var(--ck-color-base-border) #1ab394 #1ab394 ;
}
.localizedContent.has-error .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.localizedContent.has-error .ck.ck-editor__main>.ck-source-editing-area>textarea {
  border-color: var(--ck-color-base-border) #ed5565 #ed5565 ;
}
.localizedContent .ck.ck-editor__main>.ck-editor__editable,
.localizedContent .ck.ck-editor__main>.ck-source-editing-area {
  min-height:200px;
}
.localizedContent .wordCount { 
  text-align: right;
  padding: 5px;
  border: 1px solid var(--ck-color-toolbar-border);
  border-top: 0px;
  background-color: var(--ck-color-toolbar-background);
}



.tox-tinymce-aux {
  z-index:2200 !important;
}




.listPictures table.pictures th {
    width:50%;
}
.listPictures table.pictures th.ranking {
    width:15px;
}
.listPictures table.pictures td.thumbnail {
    text-align: center;
}
.listPictures table.pictures td.thumbnail img {
    max-width: 400px;
    max-height: 200px;
}
.listPictures table.pictures td.options button {
    display:block;
}
.listPictures table.pictures td.ranking a {
    display: block;
}
.listPictures .picture-upload,
  .listPictures .picture-add {
    margin-top: 5px;
}



.product-form .update-description textarea {
  min-height: 100px;
}


.fa-check.active[data-v-5167f3e8], .fa-user-circle-o.active[data-v-5167f3e8] {
  color:green;
}
.fa-check.disabled[data-v-5167f3e8], .fa-user-circle-o.disabled[data-v-5167f3e8] {
  color:red;
}




.fa-check.active[data-v-7fff71de], .fa-user-circle-o.active[data-v-7fff71de] {
  color:green;
}
.fa-check.disabled[data-v-7fff71de], .fa-user-circle-o.disabled[data-v-7fff71de] {
  color:red;
}




.weekdays .weekday {
  margin-bottom:10px;
}
.add-day-slot > div {
  border-top:1px solid #CCC;
  margin-top:5px;
  padding-top:5px;
  margin-bottom: 10px;
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
}
.add-day-slot .v-select {
  width: 120px;
  margin-right: 20px;
}
  


.fa-check.active[data-v-79641958], .fa-user-circle-o.active[data-v-79641958] {
  color:green;
}
.fa-check.disabled[data-v-79641958], .fa-user-circle-o.disabled[data-v-79641958] {
  color:red;
}





/*
.address .table td.header {
    padding-right:15px;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
    padding:0px;
}
*/



.shippingHistoryCollapse > a i:last-child {
    margin-left:5px;
    transition: 0.3s ease all;
}
.shippingHistoryCollapse > a.opened i:last-child {
    transform: rotate(180deg);
}



.promo-codes {
    margin-bottom: 20px;
}
.promo-codes .applied-codes {
    margin-top: 20px;
}
.promo-codes .applied-codes span.code {
   font-weight: 600;
   color: #28a745
}
.promo-codes .applied-codes span.note {
    margin-left:10px;
}



.button.payment {
  width: 100%;
}



.fa-check.active[data-v-9c038c30], .fa-user-circle-o.active[data-v-9c038c30] {
  color:green;
}
.fa-check.disabled[data-v-9c038c30], .fa-user-circle-o.disabled[data-v-9c038c30] {
  color:red;
}




.fa-check.active[data-v-28d6cd5d], .fa-user-circle-o.active[data-v-28d6cd5d] {
  color:green;
}
.fa-check.disabled[data-v-28d6cd5d], .fa-user-circle-o.disabled[data-v-28d6cd5d] {
  color:red;
}




.orderedActivities .col-auto.buttons {
  text-align: right;
  display:flex;
}
.orderedActivities .col-auto.buttons > div {
  margin:5px;  /* and that, will result in a 10px gap */
}

/* no wrap */
.orderedActivities .col-auto.buttons .includePastActivities label {
  white-space: nowrap;
}
.orderedActivities .isDelivered {
  font-weight: bold;
  color: #FF0000;
}

